import React from 'react';

import Meta from '../../../components/meta';

import { Link } from 'gatsby';

import Contents from '../../../components/contents';

import SidebarRecruit from '../../../components/sidebarRecruit';

import SidebarJobs from '../../../components/sidebarJobs';

const Sales = () =>{



    return(
        <>

            <Meta
                title="ソリューションプランナー"
                description="受発注システム「MOS」のソリューション提案"
            />

            <Contents>

                <div className="c-sub-bg is-sales">

                    <div className="c-sub-header">
                        <em className="c-sub-header--eng u-josef">
                        SOLUTION<br />
                        PLANNER
                        </em>

                        <h1 className="c-sub-header--title">
                        受発注システム「MOS」のソリューション提案
                        </h1>
                    </div>

                </div>



                <article className="p-recruit-jobs">

                    <section className="p-recruit-jobs-section">

                        <div className="p-recruit-section-box is-detail">
                            <div className="p-recruit-job">
                                <p className="p-recruit-job--text">
                                募集内容
                                </p>
                            </div>

                            <div className="p-recruit-cat">
                                <p className="p-recruit-cat--text">
                                REQUIRE
                                </p>
                            </div>

                            <div className="p-recruit-data">

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    この仕事について
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        国内シェアTOPクラス、400社以上で導入実績を誇る自社クラウド受発注システム『MOS』における営業をお任せします。
                                        HPへの問合せに対する反響型営業（新規開拓なし）となり、1人当たり数十社を担当します。
                                        </p>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    主な仕事内容
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        始めの1週間は商材について座学研修、2週間ほど上司がマンツーマンでOJT、一人で毎日1件ほど訪問営業(TELアポ一切なし)となります。
                                        また裁量が大きく、一から仕事の配分を考えることができるため、業務の中で、大きなやりがいを感じることができます。
                                        </p>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    必要な経験・スキル
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <ul>
                                            <li>
                                                法人営業の実務経験
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    歓迎する経験・スキル
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <ul>
                                            <li>
                                                営業統括の経験
                                            </li>

                                            <li>
                                                システム営業の経験
                                            </li>

                                            <li>
                                                提案型の商談、営業の経験
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    求める人材
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <ul>
                                            <li>
                                            コミュニケーションがとれる
                                            </li>

                                            <li>
                                            向上心がある・新しいことにチャレンジしたい
                                            </li>

                                            <li>            
                                            お客様の課題解決に取り組める
                                            </li>

                                            <li>
                                            わからないことを声に出していける
                                            </li>

                                            <li>
                                            自発的に行動できる
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    学歴
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        高卒以上
                                        </p>
                                    </div>
                                </div>

                            </div>

                            <div className="p-recruit-entry is-single">
                                <Link to="/contact" title="お問い合わせ" className="p-recruit-entry--btn">
                                採用について問い合わせる
                                </Link>
                            </div>

                        </div>

                    </section>



                    <section className="p-recruit-jobs-section">

                        <div className="p-recruit-section-box is-detail">
                            <div className="p-recruit-job">
                                <p className="p-recruit-job--text">
                                給与・待遇
                                </p>
                            </div>

                            <div className="p-recruit-cat">
                                <p className="p-recruit-cat--text">
                                SALARY / COMPENSATION
                                </p>
                            </div>

                            <div className="p-recruit-data">

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    雇用形態
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        正社員
                                        </p>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    給与
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        都度確認・応相談
                                        </p>

                                        <p className="p-recruit-data-box-cnt--text">
                                        経験・能力を考慮して決定いたします
                                        </p>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    勤務地
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        東京事務所<br />
                                        〒102-0083 東京都千代田区麹町5-3-23 日テレ四谷ビル
                                        </p>

                                        <p className="p-recruit-data-box-cnt--text">
                                        大阪事務所<br />
                                        〒530-0017 大阪府大阪市北区角田町8-1 梅田阪急ビルオフィスタワー23F
                                        </p>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    制度・福利厚生
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <ul>
                                        <li>
                                            在宅勤務（全従業員利用可）
                                            </li>
                                            <li>
                                            従業員持株会制度
                                            </li>
                                            <li>
                                            資格取得奨励金制度
                                            </li>
                                            <li>
                                            慶弔見舞金制度
                                            </li>
                                            <li>
                                            フレックスタイム制度
                                            </li>
                                            <li>
                                            PC支給
                                            </li>
                                            <li>
                                            誕生日休暇
                                            </li>
                                            <li>
                                            健康診断（年1回）
                                            </li>
                                            <li>
                                            インフルエンザ予防接種支援
                                            </li>
                                            <li>
                                            書籍購入補助
                                            </li>
                                            <li className="is-end">
                                            など
                                            </li>
                                        </ul>

                                        <div className="c-link">
                                            <Link to="/recruit/culture" title="アクロスの成長支援について知る" className="c-link--btn">
                                            アクロスの成長支援について知る<span className="c-link--cursor"></span>
                                            </Link>
                                        </div>

                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    諸手当
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        会社規程に基づき支給（実費支給 上限あり）
                                        </p>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    就業時間
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        フレックスタイム制<br />
                                        フレキシブルタイム 始業6：00～10：00　終業15：00～19：00<br />
                                        コアタイム 10:00～15:00
                                        休憩：１時間
                                        </p>
                                        <p className="p-recruit-data-box-cnt--text">
                                        時間外勤務 月平均3時間、固定残業代制（月45時間を超える時間外労働は追加で支給）
                                        </p>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    試用期間
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        3ヶ月 （試用期間中の勤務条件：変更なし）
                                        </p>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    社会保険
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        健康保険、厚生年金、雇用保険、労災保険（社会保険完備）
                                        </p>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    休日
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        年間 124日/完全週休二日制、土曜・日曜・祝日、誕生日休暇、年末年始、有給休暇 （入社半年経過時点10日）、リフレッシュ休暇（年4日）
                                        </p>
                                    </div>
                                </div>

                            </div>

                            <div className="p-recruit-entry is-single">
                                <Link to="/contact" title="お問い合わせ" className="p-recruit-entry--btn">
                                採用について問い合わせる
                                </Link>
                            </div>

                        </div>

                    </section>

                </article>



                <SidebarJobs />



                <SidebarRecruit />



            </Contents>

        </>
    )
}

export default Sales;


